import * as R from 'ramda';
// helpers/constants
import * as H from '../helpers/helpers';
//////////////////////////////////////////////////

const toNumber = (num: string) => Number(num);

export const DEFAULT_USER_FORMAT = 'MMM d';
export const DEFAULT_TIME_FORMAT = 'h:mm a';
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_MILITARY_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aa';
export const DEFAULT_US_MILITARY_DATE_TIME_FORMAT = 'MM/dd/yyyy HH:mm';
export const DEFAULT_DATE_FORMAT_FOR_LOAD_BOARDS_INTERVAL = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
// format placeholder
export const DEFAULT_DATE_PLACEHOLDER = 'mm/dd/yyyy';
export const DEFAULT_TIME_AM_PLACEHOLDER = 'hh:mm am';
export const DEFAULT_TIME_PM_PLACEHOLDER = 'hh:mm pm';
export const DEFAULT_MILITARY_TIME_PLACEHOLDER = 'hh:mm';
export const DEFAULT_DATE_TIME_PLACEHOLDER = 'mm/dd/yyyy hh:mm am';
export const DEFAULT_US_MILITARY_DATE_TIME_PLACEHOLDER = 'mm/dd/yyyy hh:mm';
// date time format config values
export const DATE_TIME_FORMAT_US = 'US';
export const DATE_TIME_FORMAT_US_24H = 'US_24H';
export const DATE_TIME_FORMAT_AM_PM_UPPERCASE = 'MM/DD/YYYY hh:mm A';
// file formats
export const FILE_FORMAT_PDF = 'PDF';
export const FILE_FORMAT_EXCEL = 'EXCEL';
// date time masks
const timeMask = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /[AaPp]/, /[Mm]/];
const dateMask = [/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
const dateTimeMask = [...dateMask, ' ', ...timeMask];
const militaryTimeMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
const usMilitaryDateTimeMask = [...dateMask, ' ', ...militaryTimeMask];

const timePipe = (value: any) => {
  if (H.isNotNilAndNotEmpty(value)) {
    let newValue = value;
    const splittedHead = R.head(R.split(':', value));
    const splittedHeadNumber = toNumber(splittedHead);

    if (R.gt(splittedHeadNumber, 12)) {
      newValue = R.replace(splittedHead, 12, value);
    }

    return R.toUpper(newValue);
  }

  return value;
};

const datePipe = (value: any) => {
  if (H.isNotNilAndNotEmpty(value)) {
    let newValue = value;
    const splitted = R.split('/', value);
    const month = R.head(splitted);
    const monthNumber = toNumber(month);
    if (R.gt(monthNumber, 12)) {
      newValue = R.replace(month, 12, value);
    }
    const day = R.nth(1, splitted);
    const dayNumber = toNumber(day);
    if (R.gt(dayNumber, 31)) {
      newValue = R.replace(day, 31, newValue);
    }
    return R.toUpper(newValue);
  }
  return value;
};

const militaryTimePipe = (value: any) => {
  if (H.isNotNilAndNotEmpty(value)) {
    let newValue = value;
    const splittedHead = R.head(R.split(':', value));
    const splittedHeadNumber = toNumber(splittedHead);

    if (R.gt(splittedHeadNumber, 24)) {
      newValue = R.replace(splittedHead, '00', value);
    }

    return R.toUpper(newValue);
  }
  return value;
};

const dateTimePipe = (value: any) => {
  if (H.isNotNilAndNotEmpty(value)) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);
    const time = R.nth(1, splitted);
    const ampm = R.nth(2, splitted);
    const dateToUse = datePipe(date);
    const timeToUse = timePipe(time);

    return R.toUpper(`${dateToUse} ${timeToUse} ${ampm}`);
  }

  return value;
};

const usMilitaryDateTimePipe = (value: any) => {
  if (H.isNotNilAndNotEmpty(value)) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);
    const time = R.nth(1, splitted);
    const dateToUse = datePipe(date);
    const timeToUse = militaryTimePipe(time);

    return R.toUpper(`${dateToUse} ${timeToUse}`);
  }

  return value;
};

export const dateTimeFormatMap = {
  [DATE_TIME_FORMAT_US]: {
    date: {
      mask: dateMask,
      pipe: datePipe,
      format: DEFAULT_DATE_FORMAT,
      placeholder: DEFAULT_DATE_PLACEHOLDER,
    },
    time: {
      mask: timeMask,
      pipe: timePipe,
      format: DEFAULT_TIME_FORMAT,
      placeholder: DEFAULT_TIME_AM_PLACEHOLDER,
    },
    dateTime: {
      mask: dateTimeMask,
      pipe: dateTimePipe,
      format: DEFAULT_DATE_TIME_FORMAT,
      placeholder: DEFAULT_DATE_TIME_PLACEHOLDER,
    },
  },
  [DATE_TIME_FORMAT_US_24H]: {
    date: {
      mask: dateMask,
      pipe: datePipe,
      format: DEFAULT_DATE_FORMAT,
      placeholder: DEFAULT_DATE_PLACEHOLDER,
    },
    time: {
      mask: militaryTimeMask,
      pipe: militaryTimePipe,
      format: DEFAULT_MILITARY_TIME_FORMAT,
      placeholder: DEFAULT_MILITARY_TIME_PLACEHOLDER,
    },
    dateTime: {
      mask: usMilitaryDateTimeMask,
      pipe: usMilitaryDateTimePipe,
      format: DEFAULT_US_MILITARY_DATE_TIME_FORMAT,
      placeholder: DEFAULT_US_MILITARY_DATE_TIME_PLACEHOLDER,
    },
  },
};

export const dateTimeFormatMapForReactDatepicker = {
  [DATE_TIME_FORMAT_US]: {
    date: {
      format: 'MM/dd/yyyy',
    },
    time: {
      format: 'hh:mm aa',
    },
    dateTime: {
      format: 'MM/dd/yyyy hh:mm aa',
    },
  },
  [DATE_TIME_FORMAT_US_24H]: {
    date: {
      format: 'MM/dd/yyyy',
    },
    time: {
      format: 'HH:mm',
    },
    dateTime: {
      format: 'MM/dd/yyyy HH:mm',
    },
  },
};

// array buffer
export const ARRAY_BUFFER_BYTE_LENGTH = 'byteLength';
