// WARNING: import enums directly to omit circular dependency

const ENUM_NO = 'NO';
const ENUM_YES = 'YES';
const ENUM_FULL = 'FULL';
const ENUM_NONE = 'NONE';
const ENUM_BOTH = 'BOTH';
const ENUM_SCAN = 'SCAN';
const ENUM_USER = 'USER';
const ENUM_SMALL = 'SMALL';
const ENUM_QUICK = 'QUICK';
const ENUM_PHOTO = 'PHOTO';
const ENUM_UPDATE = 'UPDATE';
const ENUM_DELETE = 'DELETE';
const ENUM_CREATE = 'CREATE';
const ENUM_CAMERA = 'CAMERA';
const ENUM_EVENTS = 'EVENTS';
const ENUM_PENDING = 'PENDING';
const ENUM_ARRIVED = 'ARRIVED';
const ENUM_UNKNOWN = 'UNKNOWN';
const ENUM_CARRIER = 'CARRIER';
const ENUM_DEPARTED = 'DEPARTED';
const ENUM_BRANCH = 'ENTERPRISE';
const ENUM_STANDARD = 'STANDARD';
const ENUM_DOCUMENT = 'DOCUMENT';
const ENUM_TEL_RATE = 'TEL_RATE';
const ENUM_DROP_ONLY = 'DROP_ONLY';
const ENUM_SMARTLOOK = 'SMARTLOOK';
const ENUM_COMPLETED = 'COMPLETED';
const ENUM_TEMPORARY = 'TEMPORARY';
const ENUM_PERMANENT = 'PERMANENT';
const ENUM_DISPATCHER = 'DISPATCHER';
const ENUM_ACCOUNTING = 'ACCOUNTING';
const ENUM_FOUR_KITES = 'FOUR_KITES';
const ENUM_CITIZENSHIP = 'CITIZENSHIP';
const ENUM_MAIN_CHARGE = 'MAIN_CHARGE';
const ENUM_PICKUP_ONLY = 'PICKUP_ONLY';
const ENUM_ALL_CHARGES = 'ALL_CHARGES';
const ENUM_UNAVAILABLE = 'UNAVAILABLE';
const ENUM_CURRENT_USER = 'CURRENT_USER';
const ENUM_AVAILABLE_NOW = 'AVAILABLE_NOW';
const ENUM_CURRENT_GROUPS = 'CURRENT_GROUPS';
const ENUM_CARRIER_DRIVER = 'CARRIER_DRIVER';
const ENUM_DROP_AND_PICKUP = 'DROP_AND_PICKUP';
const ENUM_INSPECTION_TYPE_GENERAL = 'GENERAL';
const ENUM_INSPECTION_TYPE_PRE_TRIP = 'PRE_TRIP';
const ENUM_INSPECTION_TYPE_POST_TRIP = 'POST_TRIP';
const ENUM_DISPATCHING_GROUP = 'DISPATCHING_GROUP';
const ENUM_ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP';
const ENUM_AVAILABLE_IN_FUTURE = 'AVAILABLE_IN_FUTURE';
const ENUM_DRIVER_AUTO_TRACKING = 'DRIVER_AUTO_TRACKING';
const ENUM_CARRIER_DRIVER_AUTO_TRACKING = 'CARRIER_DRIVER_AUTO_TRACKING';
// invoice enums
const ENUM_TOLL = 'TOLL';
const ENUM_DISCOUNT = 'DISCOUNT';
const ENUM_FUEL_CARD = 'FUEL_CARD';
const ENUM_LINE_HAUL = 'LINE_HAUL';
const ENUM_VIDEO_TOLL = 'VIDEO_TOLL';
const ENUM_ASSESSORIALS = 'ASSESSORIALS';
const ENUM_INVOICE_CHARGE = 'INVOICE_CHARGE';
const ENUM_DRIVER_PAYABLE = 'DRIVER_PAYABLE';
const ENUM_VENDOR_PAYABLE = 'VENDOR_PAYABLE';
const ENUM_CARRIER_PAYABLE = 'CARRIER_PAYABLE';
const ENUM_ADVANCED_PAYMENT = 'ADVANCED_PAYMENT';
const ENUM_CONTRACTOR_PAYABLE = 'CONTRACTOR_PAYABLE';
const ENUM_CUSTOMER_RECEIVABLE = 'CUSTOMER_RECEIVABLE';
const ENUM_OWNER_OPERATOR_PAYABLE = 'OWNER_OPERATOR_PAYABLE';
const ENUM_SERVICE_VENDOR_PAYABLE = 'SERVICE_VENDOR_PAYABLE';
const ENUM_VENDOR_EXPENSE_DISTRIBUTION_LINE = 'VENDOR_EXPENSE_DISTRIBUTION_LINE';
const ENUM_DRIVER_EXPENSE_DISTRIBUTION_LINE = 'DRIVER_EXPENSE_DISTRIBUTION_LINE';
const ENUM_CUSTOMER_INCOME_DISTRIBUTION_LINE = 'CUSTOMER_INCOME_DISTRIBUTION_LINE';
const ENUM_CARRIER_EXPENSE_DISTRIBUTION_LINE = 'CARRIER_EXPENSE_DISTRIBUTION_LINE';
const ENUM_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE = 'CONTRACTOR_EXPENSE_DISTRIBUTION_LINE';
const ENUM_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE = 'OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE';
const ENUM_SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE = 'SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE';
// days
const ENUM_SUNDAY = 'SUNDAY';
const ENUM_MONDAY = 'MONDAY';
const ENUM_FRIDAY = 'FRIDAY';
const ENUM_TUESDAY = 'TUESDAY';
const ENUM_THURSDAY = 'THURSDAY';
const ENUM_SATURDAY = 'SATURDAY';
const ENUM_WEDNESDAY = 'WEDNESDAY';

const enumLocaleMap = {
  ADD: 'titles:add',
  CLO: 'titles:clo',
  TEL: 'titles:tel',
  EDI: 'titles:edi',
  ALL: 'titles:all',
  DAY: 'titles:day',
  HOT: 'titles:hot',
  DRY: 'titles:dry',
  TASK: 'titles:task',
  DROP: 'titles:drop',
  HOUR: 'titles:hour',
  INFO: 'titles:info',
  RATE: 'titles:rate',
  FUEL: 'titles:fuel',
  FLAT: 'titles:flat',
  STOP: 'titles:stop',
  TIME: 'titles:time',
  ORDER: 'titles:clo',
  NONE: 'titles:none',
  ITEM: 'titles:item',
  NAME: 'titles:name',
  claim: 'titles:claim',
  TRUCK: 'titles:truck',
  EMAIL: 'titles:email',
  ROUTE: 'titles:route',
  HOURS: 'titles:hours',
  CHART: 'titles:chart',
  FLEET: 'titles:fleet',
  PICKUP: 'titles:pickup',
  WEIGHT: 'titles:weight',
  BROKER: 'titles:broker',
  POSTED: 'titles:posted',
  MANUAL: 'titles:manual',
  DRIVER: 'titles:driver',
  BRANCH: 'titles:branch',
  STATUS: 'titles:status',
  VENDOR: 'titles:vendor',
  MASTER: 'titles:master',
  VOLUME: 'titles:volume',
  FROZEN: 'titles:frozen',
  REPORT: 'titles:report',
  REMOVE: 'titles:remove',
  CHILLED: 'titles:chilled',
  TRAILER: 'titles:trailer',
  WARNING: 'titles:warning',
  INVOICE: 'titles:invoice',
  REMOVED: 'titles:removed',
  COVERED: 'titles:covered',
  GENERAL: 'titles:general',
  VEHICLE: 'titles:vehicle',
  PREPAID: 'titles:prepaid',
  COLLECT: 'titles:collect',
  CARRIER: 'titles:carrier',
  BILL_TO: 'titles:bill-to',
  PERCENT: 'titles:percent',
  CURRENT: 'titles:current',
  PAYROLL: 'titles:payroll',
  WEB_APP: 'titles:web-app',
  REPLACE: 'titles:replace',
  CRITICAL: 'titles:critical',
  INTERNAL: 'titles:internal',
  ENTERPRISE: 'titles:branch',
  MANUALLY: 'titles:manually',
  CUSTOMER: 'titles:customer',
  DIVISION: 'titles:division',
  NO_GROUP: 'titles:no-group',
  SERVICES: 'titles:services',
  RETRYING: 'titles:retrying',
  DISCOUNT: 'titles:discount',
  FLEET_TRUCK: 'titles:truck',
  TEMPLATE: 'titles:template',
  DISTANCE: 'titles:distance',
  QUANTITY: 'titles:quantity',
  LINEHAUL: 'titles:line-haul',
  TEMPLATES: 'titles:templates',
  MULTILINE: 'titles:multiline',
  QUICK_PAY: 'titles:quick-pay',
  TEL_COUNT: 'titles:tel-count',
  CLO_COUNT: 'titles:clo-count',
  LINE_HAUL: 'titles:line-haul',
  FLEET_DRIVER: 'titles:driver',
  TEL_REPORT: 'titles:tel-list',
  CLO_REPORT: 'titles:clo-list',
  TEL_EVENT: 'titles:tel-event',
  REFERENCE: 'titles:reference',
  EQUIPMENT: 'titles:equipments',
  HISTORICAL: 'titles:historical',
  LOAD_BOARD: 'titles:load-board',
  DIRECT_PAY: 'titles:direct-pay',
  TEL_MARGIN: 'titles:tel-margin',
  CLO_MARGIN: 'titles:clo-margin',
  FLEET_TRAILER: 'titles:trailer',
  UNASSIGNED: 'titles:unassigned',
  STATUS_CODE: 'enum:status-code',
  MOBILE_APP: 'titles:mobile-app',
  WORK_ORDER: 'titles:work-order',
  US: 'titles:us-date-time-format',
  ORDER_QUOTE: 'titles:order-quote',
  BRANCH_NAME: 'titles:branch-name',
  EMAIL_QUOTE: 'titles:email-quote',
  SINGLE_LINE: 'titles:single-line',
  CLO_CREATE: 'titles:order-create',
  CLO_SOURCE: 'titles:order-source',
  THIRD_PARTY: 'titles:third-party',
  FIRST_DATE_DAY: 'titles:date-day',
  APPROVED_BY: 'titles:approved-by',
  LOAD_STATUS: 'titles:load-status',
  EVERY_TRUCK: 'titles:every-truck',
  DAYS_BEFORE: 'titles:days-before',
  RATE_ENGINE: 'titles:rate-engine',
  TOLL_CHARGE: 'titles:toll-charge',
  TRIP_MAX_PAY: 'titles:trip-max-pay',
  FLEET_VENDOR: 'titles:fleet-vendor',
  BILL_TO_NAME: 'titles:bill-to-name',
  TEL_SPEND: 'titles:tel-total-spend',
  TRUCK_NUMBER: 'titles:truck-number',
  ASSESSORIALS: 'titles:accessorials',
  EVERY_DRIVER: 'titles:every-driver',
  HOURS_BEFORE: 'titles:hours-before',
  WARNING_LEVEL: 'enum:warning-level',
  CONTACT_BOOK: 'titles:contact-book',
  CLO_TEMPLATE: 'titles:clo-template',
  FLEET_SERVICE_ISSUE: 'titles:issue',
  TAXABLE_TOTAL: 'titles:taxable-total',
  CLO_INCOME: 'titles:clo-total-income',
  FLEET_SERVICE: 'titles:fleet-service',
  EVENT_RELATED: 'titles:event-related',
  FLEET_INVOICE: 'titles:fleet-invoice',
  HOURS_IN_STOP: 'titles:hours-in-stop',
  MINUTES_AFTER: 'titles:minutes-after',
  ROUTE_BY_LOAD: 'titles:route-by-load',
  DRIVER_FULL_NAME: 'titles:driver-name',
  SERVICE_VENDOR: 'titles:service-vendor',
  MINIMUM_MARGIN: 'titles:minimum-margin',
  TRAILER_NUMBER: 'titles:trailer-number',
  DRIVER_PAYROLL: 'titles:driver-payroll',
  US_24H: 'titles:us-date-time-format-24',
  ROUTE_TEMPLATE: 'titles:route-template',
  MINUTES_BEFORE: 'titles:minutes-before',
  CRITICAL_MARGIN: 'titles:critical-margin',
  ALLOW_ZERO_RATE: 'titles:allow-zero-rate',
  CARRIER_INVOICE: 'titles:carrier-invoice',
  VISA_EXPIRATION: 'titles:visa-expiration',
  SELECTED_TRUCKS: 'titles:selected-trucks',
  CLO_RATE_CHARGE: 'titles:clo-rate-charge',
  EXTERNAL_LOAD_BOARD: 'titles:all-results',
  TEL_RATE_CHARGE: 'titles:tel-rate-charge',
  INSTRUCTION: 'titles:special-instructions',
  SELECTED_DRIVER: 'titles:selected-drivers',
  TEL_STATUS_NOTIFICATION: 'enum:tel-status',
  LOAD_BOARD_QUOTE: 'titles:load-board-quote',
  CUSTOMER_INVOICE: 'titles:customer-invoice',
  FLEET_TRUCK_SERVICE: 'titles:truck-service',
  DOCUMENT_MISSING: 'titles:document-missing',
  CARRIER_CONTRACT: 'titles:carrier-contract',
  DISPATCHER_EMAIL: 'titles:dispatcher-emails',
  CARRIER_STATISTIC: 'title:carrier-statistic',
  DEADHEAD_DISTANCE: 'titles:deadhead-distance',
  EQUIPMENT_SERVICE: 'titles:equipment-service',
  FLEET_VENDOR_PAYROLL: 'titles:vendor-payroll',
  FACTORING_COMPANY: 'titles:factoring-company',
  CUSTOMER_CONTRACT: 'titles:customer-contract',
  LOCATION_TEMPLATE: 'titles:location-template',
  CARRIER_INSURANCE: 'titles:carrier-insurance',
  CLO_CLO_STATUS_NOTIFICATION: 'enum:clo-status',
  DISPATCHER_FULL_NAME: 'titles:dispatcher-name',
  UNAVAILABLE_PERIOD: 'titles:unavailable-period',
  MY_CARRIER_PACKETS: 'titles:my-carrier-packets',
  EVENT_ON_TIME_LATE: 'titles:event-on-time-late',
  FLEET_SELF_SERVICE: 'titles:fleet-self-service',
  ADDITIONAL_INVOICE: 'titles:additional-invoice',
  FLEET_TRAILER_SERVICE: 'titles:trailer-service',
  INTERNAL_FACTORING: 'titles:internal-factoring',
  LICENSE_EXPIRATION: 'titles:license-expiration',
  HOURS_AFTER_CREATE: 'titles:hours-after-create',
  CONTAINER_TEMPLATE: 'titles:container-template',
  SHARED_ASSESSORIAL: 'titles:shared-assessorial',
  CARRIER_MOBILE_APP: 'titles:carrier-mobile-app',
  LAST_KNOWN_LOCATION: 'titles:last-known-location',
  FIRST_AND_LAST_NAME: 'titles:first-and-last-name',
  DRIVER_PHONE_NUMBER: 'titles:driver-phone-number',
  RATE_TOTAL_MISMATCH: 'titles:rate-total-mismatch',
  TRANSPORTATION_MODE: 'titles:transportation-mode',
  ACCEPT_DECLINE_RESPONSE: 'titles:tender-response',
  PASSPORT_EXPIRATION: 'titles:passport-expiration',
  DOCUMENT_EXPIRATION: 'titles:document-expiration',
  EXCEL_RATING_RESULT: 'titles:excel-rating-result',
  CONTRACT_EXPIRATION: 'titles:contract-expiration',
  CARRIER_RATE_REQUEST: 'title:carrier-rate-request',
  DISPATCHER_PHONE_NUMBER: 'titles:dispatcher-phone',
  WARNING_AND_CRITICAL: 'titles:warning-and-critical',
  FLEET_INVOICE_REPORT: 'titles:fleet-invoice-report',
  INSURANCE_EXPIRATION: 'titles:insurance-expiration',
  FLEET_VENDOR_INVOICE: 'titles:fleet-vendor-invoice',
  FLEET_EQUIPMENT_SERVICE: 'titles:equipment-service',
  COMPENSATION_TEMPLATE: 'titles:compensation-template',
  MARGIN_VIOLATION_TYPE: 'titles:margin-violation-type',
  FLEET_VENDOR_PAYROLL_NEW: 'titles:vendor-payroll-new',
  DRIVER_PAYROLL_REPORT: 'titles:driver-payroll-report',
  GREEN_CARD_EXPIRATION: 'titles:green-card-expiration',
  FLEET_DRIVER_ASSESSORIAL: 'titles:driver-accessorial',
  FLEET_VENDOR_ASSESSORIAL: 'titles:vendor-accessorial',
  FLEET_DRIVER_COMPENSATION: 'titles:driver-compensation',
  FLEET_VENDOR_COMPENSATION: 'titles:vendor-compensation',
  INTERNAL_LOAD_NUMBER: 'titles:internal-tracking-number',
  CARRIER_INVOICE_REPORT: 'titles:carrier-invoice-report',
  CERTIFICATE_EXPIRATION: 'titles:certificate-expiration',
  TERMINATION_EXPIRATION: 'titles:termination-expiration',
  FLEET_SHARED_COMPONENT: 'titles:fleet-shared-component',
  SERVICE_VENDOR_INVOICE: 'titles:service-vendor-invoice',
  VENDOR_DRIVER_RATE_LIST: 'titles:vendor-driver-rate-list',
  CUSTOMER_MASTER_INVOICE: 'titles:customer-master-invoice',
  CUSTOMER_INVOICE_REPORT: 'titles:customer-invoice-report',
  FLEET_DISPATCH_DOCUMENT: 'titles:fleet-dispatch-document',
  REGISTRATION_EXPIRATION: 'titles:registration-expiration',
  COMPENSATION_EXPIRATION: 'titles:compensation-expiration',
  EVENT_DETENTION_NOTIFICATION: 'titles:trip-event-detention',
  DRIVER_LICENSE_EXPIRATION: 'enum:driver-license-expiration',
  FLEET_VENDOR_PAYROLL_REPORT: 'titles:vendor-payroll-report',
  FLEET_VENDOR_INVOICE_REPORT: 'titles:vendor-invoice-report',
  ACKNOWLEDGEMENT_RESPONSE: 'titles:acknowledgement-response',
  CLO_STATUS_MESSAGE_NOTIFICATION: 'titles:clo-status-message',
  TEL_STATUS_MESSAGE_NOTIFICATION: 'titles:tel-status-message',
  CARRIER_DISPATCH_DOCUMENT: 'titles:carrier-dispatch-document',
  CLO_LATE_PREDICTION_NOTIFICATION: 'titles:clo-late-prediction',
  TEL_LATE_PREDICTION_NOTIFICATION: 'titles:tel-late-prediction',
  EQUIPMENT_SERVICE_EXPIRATION: 'titles:equipment-service-expiration',
  FLEET_DRIVER_PAYROLL_ASSESSORIAL: 'titles:driver-payroll-accessorial',
  FLEET_VENDOR_PAYROLL_ASSESSORIAL: 'titles:vendor-payroll-accessorial',
  CUSTOMER_MASTER_INVOICE_REPORT: 'titles:customer-master-invoice-report',
  EQUIPMENT_COMPONENT_EXPIRATION: 'titles:equipment-component-expiration',
  WORK_AUTHENTICATION_EXPIRATION: 'titles:work-authentication-expiration',
  CARRIER_RATE_TRAILER_UPDATE_ACTION: 'titles:carrier-rate-update-action',
  DAILY_DRIVER_HOURS_ADD_HOS_LOGS: 'titles:daily-driver-hours-add-hos-logs',
  FIRST_NAME_INITIAL_AND_LAST_NAME: 'titles:first-name-initial-and-last-name',
  FIRST_NAME_AND_LAST_NAME_INITIAL: 'titles:first-name-and-last-name-initial',
  VENDOR_PAYROLL_SCIP_RELATED_TRIP_INVOICES: 'titles:vendor-payroll-skip-related-trip-invoices',
  // own names
  DAT: 'titles:dat',
  NEWTRUL: 'titles:newtrul',
  JB_HUNT: 'titles:jb-hunt',
  SAMSARA: 'titles:samsara',
  PARADE: 'titles:parade-ai',
  HUB_TRAN: 'titles:hubtran',
  TRUCK_STOP: 'titles:truckstop',
  A_DUIE_PYLE: 'titles:a-duie-pyle',
  RIST: 'titles:rist-transportation',
  PRIORITY_ONE: 'titles:priority-one',
  TRUCKER_TOOLS: 'titles:trucker-tools',
  XPO_LOGISTICS: 'titles:xpo-logistics',
  DAYTON_FREIGHT: 'titles:dayton-freight',
  BORDER_CONNECT: 'titles:border-connect',
  // statuses
  QUOTE: 'titles:quote',
  ERROR: 'titles:error',
  PENDING: 'enum:pending',
  ACTIVE: 'titles:active',
  FAILED: 'titles:failed',
  OPENED: 'titles:opened',
  CREATED: 'titles:created',
  FAILURE: 'titles:failure',
  ARRIVED: 'titles:arrived',
  SUCCESS: 'titles:success',
  RESOLVED: 'titles:resolved',
  DEPARTED: 'titles:departed',
  APPROVED: 'titles:approved',
  DISABLED: 'titles:disabled',
  REJECTED: 'titles:rejected',
  NO_MATCH: 'titles:no-match',
  COMPLETED: 'enum:completed',
  CANCELED: 'titles:canceled',
  ESTIMATED: 'enum:estimated',
  EXPORTED: 'titles:exported',
  INACTIVE: 'titles:inactive',
  CHECKED_IN: 'enum:checked-in',
  EXTRACTED: 'titles:extracted',
  PROCESSED: 'titles:processed',
  ALL_FAILED: 'titles:all-failed',
  DISPATCHED: 'titles:dispatched',
  ALL_SUCCESS: 'titles:all-success',
  IN_PROGRESS: 'titles:in-progress',
  RATED: 'titles:load-status-rated',
  DRAFT: 'titles:rate-status-draft',
  QUOTED: 'titles:load-status-quoted',
  EXPIRED: 'titles:rate-status-expired',
  PLANNED: 'titles:load-status-planned',
  EXPORT_FAILED: 'titles:export-failed',
  DROP_COMPLETED: 'enum:drop-completed',
  STOP_CHECKED_IN: 'enum:stop-checked-in',
  ACCEPTED: 'titles:rate-status-accepted',
  DECLINED: 'titles:rate-status-declined',
  DROP_CHECKED_IN: 'titles:drop-checked-in',
  PICKUP_COMPLETED: 'enum:pickup-completed',
  DELIVERED: 'titles:load-status-delivered',
  PARTIAL_SUCCESS: 'titles:partial-success',
  BOOKED: 'titles:load-status-booked-status',
  PARTIALLY_SUCCESS: 'titles:partial-success',
  IN_TRANSIT: 'titles:load-status-in-transit',
  DISPATCH_EXPIRED: 'titles:dispatch-expired',
  DISPATCH_REJECTED: 'titles:dispatch-rejected',
  UNSCHEDULED: 'titles:load-status-unscheduled',
  READY_FOR_BILLING: 'titles:ready-for-billing',
  PICKUP_CHECKED_IN: 'titles:pickup-checked-in',
  EXPORTED_TO_FACTORING: 'titles:exported-to-factoring',
  TRANSACTION_UNASSIGNED: 'titles:transaction-unassigned',
  // days
  SUNDAY: 'titles:sunday',
  MONDAY: 'titles:monday',
  FRIDAY: 'titles:friday',
  TUESDAY: 'titles:tuesday',
  THURSDAY: 'titles:thursday',
  SATURDAY: 'titles:saturday',
  WEDNESDAY: 'titles:wednesday',
  // operations
  IN: 'titles:in',
  MORE: 'titles:more',
  EQUAL: 'titles:equal',
  RANGE: 'titles:range',
  NOT_EQUAL: 'titles:not-equal',
  // duty status code
  ON_DUTY: 'titles:on-duty',
  SLEEPER: 'titles:sleeper',
  DRIVING: 'titles:driving',
  UNKNOWN: 'titles:unknown',
  OFF_DUTY: 'titles:off-duty',
  YARD_MOVE: 'titles:yard-move',
  SLEEPER_BED: 'titles:sleeper-bed',
  INTERMEDIATE: 'titles:intermediate',
  WAITING_TIME: 'titles:waiting-time',
  PERSONAL_CONVEYANCE: 'titles:personal-conveyance',
  // chart types
  PIE: 'titles:pie',
  BAR: 'titles:bar',
  LINE: 'titles:line',
  TOTAL: 'titles:total',
  BAR3D: 'titles:bar-3d',
  PIE3D: 'titles:pie-3d',
  // notification triggers
  STATIC: 'enum:static',
  CLO_RATE: 'enum:clo-rate',
  USER_EMAIL: 'enum:user-email',
  TEL_STATUS: 'enum:tel-status',
  CLO_STATUS: 'enum:clo-status',
  CLO_BILL_TO: 'enum:clo-bill-to',
  MACRO_POINT: 'titles:macro-point',
  LATE_DELIVERY: 'titles:late-delivery',
  STATUS_MESSAGE: 'enum:status-message',
  EVENT_DETENTION: 'enum:event-detention',
  EVENT_LATE_DATE: 'titles:stop-late-date',
  LATE_PREDICTION: 'titles:late-prediction',
  EDI_TRANSACTION: 'titles:edi-transaction',
  UNPLANNED_EVENT: 'titles:unplanned-event',
  EVENT_EARLY_DATE: 'enum:event-early-date',
  DOCUMENT_UPLOAD: 'titles:document-upload',
  EXTERNAL_PORTAL: 'titles:external-portal',
  DOCUMENT_SOURCE: 'titles:document-source',
  INTEGRATION_TYPE: 'titles:integration-type',
  MARGIN_VIOLATION: 'titles:margin-violation',
  ENTERPRISE_BILL_TO: 'enum:enterprise-bill-to',
  INTEGRATION_ERROR: 'titles:integration-error',
  DRIVER_TERMINATION: 'enum:driver-termination',
  TRUCK_SERVICE_DATE: 'titles:truck-service-date',
  MACRO_POINT_STATUS: 'titles:macro-point-status',
  ORDER_QUOTE_ACCEPT: 'titles:order-quote-accept',
  DRIVER_CHAT_MESSAGE: 'titles:driver-chat-message',
  CARRIER_CHAT_MESSAGE: 'titles:carrier-chat-message',
  CARRIER_DEACTIVATION: 'titles:carrier-deactivation',
  SCANNED_VIN_MISMATCH: 'titles:scanned-vin-mismatch',
  APPOINTMENT_REQUIRED: 'titles:appointment-required',
  TRAILER_SERVICE_DATE: 'titles:trailer-service-date',
  TRUCK_ISSUE_CREATION: 'titles:truck-issue-creation',
  TRUCK_OUT_OF_SERVICE: 'titles:truck-out-of-service',
  CUSTOMER_CHAT_MESSAGE: 'titles:customer-chat-message',
  TRAILER_ISSUE_CREATION: 'titles:trailer-issue-creation',
  EVENT_NO_STATUS_UPDATE: 'titles:event-no-status-update',
  TRAILER_OUT_OF_SERVICE: 'titles:trailer-out-of-service',
  TRUCKER_TOOLS_BOOK_IT_NOW: 'titles:trucker-tools-book-it-now',
  TRUCK_DOCUMENT_EXPIRATION: 'titles:truck-document-expiration',
  ORDER_QUOTE_RATE_PROVIDED: 'titles:order-quote-rate-provided',
  EDI_TRANSACTION_ORDER_EXPIRATION: 'titles:edi-expiration-date',
  ORDER_QUOTE_RATE_REQUESTED: 'titles:order-quote-rate-requested',
  TRUCK_INSURANCE_EXPIRATION: 'titles:truck-insurance-expiration',
  DRIVER_DOCUMENTS_EXPIRATION: 'titles:driver-document-expiration',
  DISPATCHER_CLO_CHAT_MESSAGE: 'titles:dispatcher-clo-chat-message',
  DISPATCHER_TEL_CHAT_MESSAGE: 'titles:dispatcher-tel-chat-message',
  CARRIER_CONTRACT_EXPIRATION: 'titles:carrier-contract-expiration',
  CARRIER_DOCUMENT_EXPIRATION: 'titles:carrier-document-expiration',
  TRAILER_DOCUMENT_EXPIRATION: 'titles:trailer-document-expiration',
  EVENT_DATES_UPDATE_FROM_EDI: 'titles:event-dates-update-from-edi',
  CARRIER_AUTO_DISPATCH_ERROR: 'titles:carrier-auto-dispatch-error',
  CARRIER_RATE_TRAILER_CHANGE: 'titles:carrier-rate-trailer-change',
  CARRIER_INSURANCE_EXPIRATION: 'titles:carrier-insurance-expiration',
  TRAILER_INSURANCE_EXPIRATION: 'titles:trailer-insurance-expiration',
  DRIVER_CERTIFICATE_EXPIRATION: 'enum:driver-certificate-expiration',
  ENTERPRISE_CREDIT_LIMIT_PERCENT: 'titles:branch-credit-limit-percent',
  TRUCK_REGISTRATION_EXPIRATION: 'titles:truck-registration-expiration',
  CARRIER_RATE_NO_TRAILER_MATCH: 'titles:carrier-rate-no-trailer-match',
  CARRIER_CERTIFICATE_EXPIRATION: 'titles:carrier-certificate-expiration',
  API_CARRIER_AUTO_DISPATCH_ERROR: 'titles:api-carrier-auto-dispatch-error',
  TRAILER_REGISTRATION_EXPIRATION: 'titles:trailer-registration-expiration',
  CARRIER_WATCHLIST_DETACH_REMOVED: 'titles:carrier-removed-from-watch-list',
  CONTAINER_RAIL_BILLING_COMPLETED: 'titles:container-rail-billing-completed',
  APPOINTMENT_REQUIRED_AFTER_CREATE: 'titles:appointment-required-after-create',
  SERVICE_ISSUE_SERVICE_VENDOR_USERS_EMAILS: 'titles:service-vendor-users-emails',
  DRIVER_CITIZENSHIP_VISA_EXPIRATION: 'titles:driver-citizenship-visa-expiration',
  TRUCK_COMPONENT_WARRANTY_EXPIRATION: 'titles:truck-component-warranty-expiration',
  STATUS_MESSAGE_STATUS_TYPE_COMPLETED: 'enum:status-message-status-type-completed',
  TRAILER_COMPONENT_WARRANTY_EXPIRATION: 'titles:trailer-component-warranty-expiration',
  DRIVER_CITIZENSHIP_PASSPORT_EXPIRATION: 'titles:driver-citizenship-passport-expiration',
  DRIVER_COMPENSATION_DETAILS_EXPIRATION: 'titles:driver-compensation-details-expiration',
  DRIVER_CITIZENSHIP_WORK_AUTH_EXPIRATION: 'titles:driver-citizenship-work-auth-expiration',
  DRIVER_CITIZENSHIP_GREEN_CARD_EXPIRATION: 'titles:driver-citizenship-green-card-expiration',
  // carrier deactivation triggers
  STATISTIC_DECLINE: 'titles:decline-percentage',
  STATISTIC_ON_TIME_TEL: 'titles:on-time-percentage',
  STATISTIC_INVOICE_TIME: 'titles:invoice-upload-time',
  STATISTIC_DOCUMENT_TIME: 'titles:document-upload-time',
  STATISTIC_ON_TIME_DROP: 'titles:on-time-drop-percentage',
  STATISTIC_ON_TIME_PICKUP: 'titles:on-time-pickup-percentage',
  STATISTIC_EVENT_COMPLETE_BY_CARRIER: 'titles:events-complete-by-carrier-percentage',
  // macro point status code status types
  TRACKING_NOW: 'titles:tracking-now',
  READY_TO_TRACK: 'titles:ready-to-track',
  DENIED_BY_DRIVER: 'titles:denied-by-driver',
  REPORTED_LANDLINE: 'titles:reported-landline',
  STOPPED_BY_CREATOR: 'titles:stopped-by-creator',
  PHONE_NOT_LOCATABLE: 'titles:phone-not-locatable',
  INVALID_TRUCK_NUMBER: 'titles:invalid-truck-number',
  REFUSED_INSTALLATION: 'titles:refused-installation',
  REPORTED_WRONG_NUMBER: 'titles:reported-wrong-number',
  INVALID_TRAILER_NUMBER: 'titles:invalid-trailer-number',
  REQUESTING_APP_INSTALL: 'titles:requesting-app-install',
  REQUESTING_INSTALLATION: 'titles:requesting-installation',
  STOPPED_EARLY_BY_DRIVER: 'titles:stopped-early-by-driver',
  EXPIRED_WITHOUT_LOCATION: 'titles:expired-without-location',
  LOCATION_HIDDEN_BY_DRIVER: 'titles:location-hidden-by-driver',
  DRIVER_PHONE_UNRESPONSIVE: 'titles:driver-phone-unresponsive',
  TRACKING_WAITING_FOR_UPDATE: 'titles:tracking-waiting-for-update',
  EXPIRED_WITHOUT_INSTALLATION: 'tiles:expired-without-installation',
  TRACKING_COMPLETE_SUCCESSFULLY: 'titles:tracking-complete-successfully',
  EXPIRED_WITH_LOCATION_HIDDEN_BY_DRIVER: 'titles:expired-with-location-hidden-by-driver',
  EXPIRED_WITH_DRIVER_PHONE_UNRESPONSIVE: 'titles:expired-with-driver-phone-unresponsive',
  // quantity
  BOX: 'titles:box',
  BIN: 'titles:bin',
  BAG: 'titles:bags',
  SACK: 'titles:sack',
  PAIL: 'titles:pail',
  TOTE: 'titles:tote',
  RACK: 'titles:racks',
  ROLL: 'titles:rolls',
  BALES: 'titles:bales',
  CASES: 'titles:cases',
  DRUMS: 'titles:drums',
  SKIDS: 'titles:skids',
  CRATES: 'titles:crates',
  GALLON: 'titles:gallon',
  PALLET: 'titles:pallet',
  PIECES: 'titles:pieces',
  BUNDLES: 'titles:bundles',
  CARTONS: 'titles:cartons',
  GAYLORD: 'titles:gaylord',
  CONTAINER: 'titles:container',
  TRUCK_LOAD: 'titles:truckload',
  // TODO: check why here as not enums, check reference scopes
  user: 'titles:user',
  item: 'titles:item',
  route: 'titles:route',
  branch: 'titles:branch',
  carrier: 'titles:carrier',
  location: 'titles:location',
  'load-event': 'titles:load-event',
  'fleet-truck': 'titles:fleet-truck',
  'fleet-driver': 'titles:fleet-driver',
  'fleet-vendor': 'titles:fleet-vendor',
  'fleet-trailer': 'titles:fleet-trailer',
  'fleet-invoice': 'titles:fleet-invoice',
  'carrier-invoice': 'titles:carrier-invoice',
  'customer-invoice': 'titles:customer-invoice',
  // document sending types
  LINK: 'titles:link',
  ATTACHMENT: 'titles:attachment',
  ATTACHMENT_AND_LINK: 'titles:attachment-and-link',
  // edi direction types
  INBOUND: 'titles:inbound',
  OUTBOUND: 'titles:outbound',
  // claim statuses
  CLAIM_STATUS_TYPE_OPEN: 'titles:opened',
  CLAIM_STATUS_TYPE_CLOSED: 'titles:closed',
  CLAIM_STATUS_TYPE_DENIED: 'titles:denied',
  CLAIM_STATUS_TYPE_APPROVED: 'titles:approved',
  CLAIM_STATUS_TYPE_UNDER_REVIEW: 'titles:under-review',
  // priority
  LOW: 'titles:low',
  HIGH: 'titles:high',
  MEDIUM: 'titles:medium',
  // trucking methods
  HOOK: 'titles:hook',
  LIFE_LOAD: 'titles:life-load',
  LIFE_UNLOAD: 'titles:life-unload',
  // enums
  [ENUM_NO]: 'titles:no',
  [ENUM_YES]: 'titles:yes',
  [ENUM_FULL]: 'titles:full',
  [ENUM_NONE]: 'titles:none',
  [ENUM_BOTH]: 'titles:both',
  [ENUM_USER]: 'titles:user',
  [ENUM_SCAN]: 'titles:scan',
  [ENUM_SMALL]: 'titles:small',
  [ENUM_QUICK]: 'titles:quick',
  [ENUM_PHOTO]: 'titles:photo',
  [ENUM_EVENTS]: 'titles:stops',
  [ENUM_CAMERA]: 'titles:camera',
  [ENUM_BRANCH]: 'titles:branch',
  [ENUM_PENDING]: 'enum:pending',
  [ENUM_UPDATE]: 'actions:update',
  [ENUM_DELETE]: 'actions:delete',
  [ENUM_CREATE]: 'actions:create',
  [ENUM_ARRIVED]: 'titles:arrived',
  [ENUM_UNKNOWN]: 'titles:unknown',
  [ENUM_CARRIER]: 'titles:carrier',
  [ENUM_STANDARD]: 'titles:standard',
  [ENUM_DOCUMENT]: 'titles:document',
  [ENUM_COMPLETED]: 'enum:completed',
  [ENUM_SMARTLOOK]: 'enum:smartlook',
  [ENUM_TEL_RATE]: 'titles:trip-rate',
  [ENUM_DROP_ONLY]: 'titles:drop-only',
  [ENUM_TEMPORARY]: 'titles:temporary',
  [ENUM_PERMANENT]: 'titles:permanent',
  [ENUM_ACCOUNTING]: 'titles:accounting',
  [ENUM_DISPATCHER]: 'titles:dispatcher',
  [ENUM_FOUR_KITES]: 'titles:four-kites',
  [ENUM_CITIZENSHIP]: 'titles:citizenship',
  [ENUM_PICKUP_ONLY]: 'titles:pickup-only',
  [ENUM_ALL_CHARGES]: 'titles:all-charges',
  [ENUM_MAIN_CHARGE]: 'titles:main-charge',
  [ENUM_CURRENT_USER]: 'titles:current-user',
  [ENUM_CURRENT_GROUPS]: 'titles:current-groups',
  [ENUM_DROP_AND_PICKUP]: 'titles:pickup-and-drop',
  [ENUM_CARRIER_DRIVER]: 'titles:carrier-app-driver',
  [ENUM_ARRIVED_AT_PICKUP]: 'titles:arrived-at-pickup',
  [ENUM_DRIVER_AUTO_TRACKING]: 'titles:driver-auto-tracking',
  [ENUM_INSPECTION_TYPE_GENERAL]: 'titles:inspection-type-general',
  [ENUM_INSPECTION_TYPE_PRE_TRIP]: 'titles:inspection-type-pre-trip',
  [ENUM_INSPECTION_TYPE_POST_TRIP]: 'titles:inspection-type-post-trip',
  [ENUM_CARRIER_DRIVER_AUTO_TRACKING]: 'titles:carrier-app-driver-auto-tracking',
  // qb account mapping account types
  [ENUM_TOLL]: 'titles:toll-charge',
  [ENUM_DISCOUNT]: 'titles:discount',
  [ENUM_FUEL_CARD]: 'titles:fuel-card',
  [ENUM_LINE_HAUL]: 'titles:line-haul',
  [ENUM_ASSESSORIALS]: 'titles:accessorial',
  [ENUM_INVOICE_CHARGE]: 'titles:invoice-charge',
  [ENUM_DRIVER_PAYABLE]: 'titles:driver-payable',
  [ENUM_VENDOR_PAYABLE]: 'titles:vendor-payable',
  [ENUM_CARRIER_PAYABLE]: 'titles:carrier-payable',
  [ENUM_ADVANCED_PAYMENT]: 'titles:advance-payment',
  [ENUM_CONTRACTOR_PAYABLE]: 'titles:contractor-payable',
  [ENUM_CUSTOMER_RECEIVABLE]: 'titles:customer-receivable',
  [ENUM_OWNER_OPERATOR_PAYABLE]: 'titles:owner-operator-payable',
  [ENUM_SERVICE_VENDOR_PAYABLE]: 'titles:service-vendor-payable',
  [ENUM_VENDOR_EXPENSE_DISTRIBUTION_LINE]: 'titles:vendor-expense-distribution-line',
  [ENUM_DRIVER_EXPENSE_DISTRIBUTION_LINE]: 'titles:driver-expense-distribution-line',
  [ENUM_CUSTOMER_INCOME_DISTRIBUTION_LINE]: 'titles:customer-income-distribution-line',
  [ENUM_CARRIER_EXPENSE_DISTRIBUTION_LINE]: 'titles:carrier-expense-distribution-line',
  [ENUM_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE]: 'titles:contractor-expense-distribution-line',
  [ENUM_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE]: 'titles:owner-operator-expense-distribution-line',
  [ENUM_SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE]: 'titles:service-vendor-expense-distribution-line',
};

const ENUMS = {
  ENUM_NO,
  ENUM_YES,
  ENUM_FULL,
  ENUM_NONE,
  ENUM_SCAN,
  ENUM_BOTH,
  ENUM_TOLL,
  ENUM_USER,
  ENUM_SMALL,
  ENUM_PHOTO,
  ENUM_QUICK,
  ENUM_CAMERA,
  ENUM_BRANCH,
  ENUM_EVENTS,
  ENUM_PENDING,
  ENUM_UNKNOWN,
  ENUM_ARRIVED,
  ENUM_DEPARTED,
  ENUM_DOCUMENT,
  ENUM_TEL_RATE,
  ENUM_STANDARD,
  ENUM_COMPLETED,
  ENUM_DROP_ONLY,
  ENUM_TEMPORARY,
  ENUM_PERMANENT,
  ENUM_ACCOUNTING,
  ENUM_DISPATCHER,
  ENUM_VIDEO_TOLL,
  ENUM_CITIZENSHIP,
  ENUM_PICKUP_ONLY,
  ENUM_ALL_CHARGES,
  ENUM_MAIN_CHARGE,
  ENUM_UNAVAILABLE,
  ENUM_CURRENT_USER,
  ENUM_AVAILABLE_NOW,
  ENUM_CURRENT_GROUPS,
  ENUM_DROP_AND_PICKUP,
  ENUM_ARRIVED_AT_PICKUP,
  ENUM_DISPATCHING_GROUP,
  ENUM_AVAILABLE_IN_FUTURE,
  ENUM_INSPECTION_TYPE_GENERAL,
  ENUM_INSPECTION_TYPE_PRE_TRIP,
  ENUM_INSPECTION_TYPE_POST_TRIP,
  // days
  ENUM_SUNDAY,
  ENUM_MONDAY,
  ENUM_FRIDAY,
  ENUM_TUESDAY,
  ENUM_THURSDAY,
  ENUM_SATURDAY,
  ENUM_WEDNESDAY,
};

export {
  ENUMS,
  enumLocaleMap,
};
